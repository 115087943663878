import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

const Loading = () =>
{
    return (
        <div className="full-screen-container green-bg">
          <Spinner animation="border" variant="light" />
        </div>
      );
}

export default Loading;