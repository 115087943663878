import React, {useState, useEffect} from "react";
import Layout from '../../components/Layout'
import Loading from '../../components/Loading'
import Error from '../../components/Error'
import Success from '../../components/Success'
import { useQueryParamString } from 'react-use-query-param-string';
import {Reward} from '../../services/reward'


const RewardPage = () => {

    const [accountId, setAccountId, accountIdInitialized] = useQueryParamString("account", "");
    const [rewardId, setRewardId, rewardIdInitialized] = useQueryParamString("id", "");
    const [rewardGuid, setRewardGuid, rewardGuidInitialized] = useQueryParamString("guid", "");
    const [accountRewardResponse, setAccountRewardResponse] = useState();

    useEffect( () => 
    {
        const fetchData = async() =>
        {
            const body = {code : rewardGuid};
            const response = await Reward.redeem(accountId, rewardId, body);
            setAccountRewardResponse(response);
            console.log(accountRewardResponse);
        }
        if(accountIdInitialized && rewardIdInitialized && rewardGuidInitialized & accountId != "" && rewardId != "" && rewardGuid != "")
            fetchData();
    }, [accountId, rewardId, rewardGuid, accountIdInitialized, rewardIdInitialized, rewardGuidInitialized]);

    if(accountRewardResponse)
    {
        if(accountRewardResponse.status == 200)
            return ( <Success message="QR válido" data={accountRewardResponse.data.data} /> )
        else
            return ( <Error message="Parece ser que este QR no es válido"/> )
    }
    else
        return (<Loading />)
    

}
export default RewardPage