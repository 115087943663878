import React from 'react'
import { XCircleFill } from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Error = ({message}) =>
{
    return (
        <div className="full-screen-container">
          <Container>
            <Row>
              <Col className="has-text-centered">
                <XCircleFill color="red" size={96} />
              </Col>
            </Row>
            <Row>
              <Col className="is-size-3 has-text-weight-bold has-text-centered">
                <h2>{message}</h2>
              </Col>
            </Row>
          </Container>
        </div>
      );
}

export default Error;