import axios from "axios";
import { API_URL } from "gatsby-env-variables"

const apiClient = axios.create({
    baseURL: API_URL
  });

  apiClient.interceptors.request.use(
    config => {
        config.headers = { 
            'Accept': 'application/json',
            'Content-type': 'application/json;charset=utf-8'
        }
      return config;
    },
    error => Promise.reject(error.response),
  );

  apiClient.interceptors.response.use((response) =>
  response,
  async (error) => {
    return Promise.resolve(error.response);
  },
);

  const { get, post, put, delete: destroy, patch } = apiClient;
  export { get, post, put, destroy, patch};